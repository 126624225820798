import PeriodService from '../../services/PeriodService'
import { formatarDataInput, formatarDataComHora } from '@/helpers';
import router from '@/router'

const state = () => ({
    loadingPeriods: false,
    period: null,
    new_period: {
        name: "",
        description: "",
        diretor: "",
        date_start: null,
        date_end: null,
        status: "ativo",
        Plans: []
    },
    periods: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_period: false
})
  
const mutations = {
    SET_PERIOD: (state, payload) => {
        state.period = payload
    },
    SET_PERIODS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.periods = payload.items
        }else{
            state.periods = [...state.periods, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_PERIODS: (state, payload) => {
        state.loadingPeriod = payload
    },
    SET_SHOW_MODAL_NEW_PERIOD: (state, payload) => {
        state.show_modal_new_period = payload
    },
}

const actions = {
    async fetchPeriod({commit}, id){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.getOne(id);
            const data = response.data.item
            if(data){
                data.date_start = await formatarDataInput(data.date_start);
                data.date_end = await formatarDataInput(data.date_end);
                data.createdAt = await formatarDataComHora(data.createdAt);
                data.updatedAt = await formatarDataComHora(data.updatedAt);
            }
            commit('SET_PERIOD', data)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriods({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'DESC' } }){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.get(keyword, pageNumber, order);
            commit('SET_PERIODS', response.data)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriodsActives({commit}){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.getActives();
            commit('SET_PERIODS', response.data)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPeriod({commit}, data){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_NEW_PERIOD', false)
            state.new_period = { payment_method_id: "", transaction_amount: null, installments: 1, description: "", UserId: null, SubscriptionId: null, PlanId: null, CuponId: null, DiscountId: null, transaction_id: null, external_reference: null, customer_id: null, identificationNumber: null, cardholderEmail: null, status: "ativo" };
            router.push({path: `/form_period/${response.data.item.id}`});
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadePeriod({commit}, data){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPeriod: state => state.period,
    getNewPeriod: state => state.new_period,
    getPeriods: state => state.periods,
    getLoadingPeriod: state => state.loadingPeriod,
    getShowModalNewPeriod: state => state.show_modal_new_period
}

export default {
    state,
    getters,
    mutations,
    actions
};