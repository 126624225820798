import CuponService from '../../services/CuponService'
import router from '@/router'

const state = () => ({
    loadingCupons: false,
    cupon: null,
    new_cupon: {
        cuponCode: '',
        description: '',
        value: null,
        typeCupon: 'porcentagem',
        date_start: null,
        date_end: null,
        status: 'ativo'
    },
    cupons: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_cupon: false
})
  
const mutations = {
    SET_CUPON: (state, payload) => {
        state.cupon = payload
    },
    SET_CUPONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.cupons = payload.items
        }else{
            state.cupons = [...state.cupons, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_CUPONS: (state, payload) => {
        state.loadingCupon = payload
    },
    SET_SHOW_MODAL_NEW_CUPON: (state, payload) => {
        state.show_modal_new_cupon = payload
    },
}

const actions = {
    async fetchCupon({commit}, id){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.getOne(id);
            commit('SET_CUPON', response.data.item)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCupons({commit}, data = {}){
        try{
            commit('SET_LOADING_CUPONS', true)
            const keyword = data?.keyword || '';
            const pageNumber = data?.pageNumber || 1;
            const pageSize = data?.pageSize || 100;
            const order = data?.order || { name: 'ID', column: 'ID', sort: 'ASC' };
            const response = await CuponService.get(keyword, pageNumber, pageSize, order);
            commit('SET_CUPONS', response.data)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCupon({commit, state}, data){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.create(data);
            commit('SET_ALERT', { heading: 'success', message: response.data.message });
            state.new_discount = { cuponCode: '', description: '', value: null, typeCupon: 'porcentagem', date_start: null, date_end: null, status: 'ativo'},
            router.push({path: `/form_cupon/${response.data.item.id}`});
            commit('SET_SHOW_MODAL_NEW_CUPON', false)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCupon({commit}, data){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCupon: state => state.cupon,
    getNewCupon: state => state.new_cupon,
    getCupons: state => state.cupons,
    getLoadingCupon: state => state.loadingCupon,
    getShowModalNewCupon: state => state.show_modal_new_cupon
}

export default {
    state,
    getters,
    mutations,
    actions
};