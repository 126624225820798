import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`subscription?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`subscription/${id}`);
    },
    getOneMP(id) {
        return Api().get(`mercadopago/subscription/${id}`);
    },
    create(data) {
        return Api().post(`subscription`, data);
    },
    update(data) {
        return Api().put(`subscription/${data.id}`, data);
    },
    updadeSubscriptionMPbyCategory(data) {
        return Api().put(`mercadopago/subscription/byCategory/${data.id}`, data);
    },
}