import SubscriptionService from '../../services/SubscriptionService'
import { formatarDataComHora } from '@/helpers';

const state = () => ({
    loadingSubscriptions: false,
    subscription: null,
    subscription_mp: null,
    subscriptions: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_subscription: false
})
  
const mutations = {
    SET_SUBSCRIPTION: (state, payload) => {
        state.subscription = payload
    },
    SET_SUBSCRIPTION_MP: (state, payload) => {
        state.subscription_mp = payload
    },
    SET_SUBSCRIPTIONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.subscriptions = payload.items
        }else{
            state.subscriptions = [...state.subscriptions, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_SUBSCRIPTIONS: (state, payload) => {
        state.loadingSubscription = payload
    },
    SET_SHOW_MODAL_SUBSCRIPTION: (state, payload) => {
        state.show_modal_new_subscription = payload
    },
}

const actions = {
    async fetchSubscription({commit}, id){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.getOne(id);
            const data = response.data.item
            if(data){
                data.free_period_start_date = await formatarDataComHora(data.free_period_start_date);
                data.free_period_end_date = await formatarDataComHora(data.free_period_end_date);
                data.createdAt = await formatarDataComHora(data.createdAt);
                data.updatedAt = await formatarDataComHora(data.updatedAt);
            }
            commit('SET_SUBSCRIPTION', data)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchSubscription_MP({commit}, id){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.getOneMP(id);
            commit('SET_SUBSCRIPTION_MP', response.data.item)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchSubscriptions({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.get(keyword, pageNumber, order);
            commit('SET_SUBSCRIPTIONS', response.data)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createSubscription({commit}, data){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_SUBSCRIPTION', false)
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeSubscription({commit}, data){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_SUBSCRIPTIONS', false)
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeSubscriptionByCategory({commit}, data){
        try{
            commit('SET_LOADING_SUBSCRIPTIONS', true)
            const response = await SubscriptionService.updadeSubscriptionMPbyCategory(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            return response.data; 
        } catch (error) {
            commit('SET_LOADING_SUBSCRIPTIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            throw error; // Lança o erro para que possa ser tratado na função chamada
        }
    },
}

const getters = {
    getSubscription: state => state.subscription,
    getSubscriptionMP: state => state.subscription_mp,
    getSubscriptions: state => state.subscriptions,
    getLoadingSubscription: state => state.loadingSubscription,
    getShowModalNewSubscription: state => state.show_modal_new_subscription
}

export default {
    state,
    getters,
    mutations,
    actions
};