import Api from './Api'

export default {
    get(keyword, pageNumber, pageSize, order) {
        return Api().get(`cupon?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`cupon/${id}`);
    },
    create(data) {
        return Api().post(`cupon`, data);
    },
    update(data) {
        return Api().put(`cupon/${data.id}`, data);
    }
}