import { formatarData, formatarDataComHora, formatarDataInput } from '@/helpers';

export const globalMixin = {
    methods: {
        formatarDataComHora(date) {
            return formatarDataComHora(date);  // Com hora
        },
        formatarData(date) {
            return formatarData(date);  // Sem hora
        },
        formatarDataInput(date) {
            return formatarDataInput(date);  // Sem hora Input
        }
    }
};