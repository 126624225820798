import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`plan?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getByUser(UserId, keyword, pageNumber, order, limit) {
        return Api().get(`plan/my?UserId=${UserId}&keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}&limit=${limit}`);
    },
    getPending() {
        return Api().get(`plan/pending`);
    },
    getOne(id) {
        return Api().get(`plan/${id}`);
    },
    update(data) {
        return Api().put(`plan/${data.id}`, data);
    },
}