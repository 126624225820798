import AdmService from '../../services/AdmService'

const state = () => ({
    loadingAdms: false,
    adm: null,
    adms: [],
    total: 0,
    pages: 0,
    page: 1,
})
  
const mutations = {
    SET_ADM: (state, payload) => {
        state.adm = payload
    },
    SET_ADMS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.adms = payload.itens
        }else{
            state.adms = [...state.adms, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_ADMS: (state, payload) => {
        state.loadingAdm = payload
    },
   
}

const actions = {
    async fetchAdm({commit}, id){
        try{
            commit('SET_LOADING_ADMS', true)
            const response = await AdmService.getOne(id);
            commit('SET_ADM', response.data.item)
            commit('SET_LOADING_ADMS', false)
        } catch (error) {
            commit('SET_LOADING_ADMS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAdms({commit}, params = {}) {
        try{
            const { keyword = '', pageNumber = 1, pageSize = 12, order = { name: 'ID', column: 'ID', sort: 'ASC' } } = params;
            commit('SET_LOADING_ADMS', true)
            const response = await AdmService.get(keyword, pageNumber, pageSize, order);
            commit('SET_ADMS', response.data)
            commit('SET_LOADING_ADMS', false)
        } catch (error) {
            commit('SET_LOADING_ADMS', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getAdm: state => state.adm,
    getAdms: state => state.adms,
    getLoadingAdm: state => state.loadingAdm,
}

export default {
    state,
    getters,
    mutations,
    actions
};