import DiscountService from '../../services/DiscountService'
import router from '@/router'

const state = () => ({
    loadingDiscounts: false,
    discount: null,
    new_discount: {
        name: '',
        description: '',
        value: null,
        typeDiscount: 'porcentagem',
        date_start: null,
        date_end: null,
        status: 'ativo'
    },
    discounts: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_discount: false
})
  
const mutations = {
    SET_DISCOUNT: (state, payload) => {
        state.discount = payload
    },
    SET_DISCOUNTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.discounts = payload.items
        }else{
            state.discounts = [...state.discounts, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_DISCOUNTS: (state, payload) => {
        state.loadingDiscount = payload
    },
    SET_SHOW_MODAL_NEW_DISCOUNT: (state, payload) => {
        state.show_modal_new_discount = payload
    },
}

const actions = {
    async fetchDiscount({commit}, id){
        try{
            commit('SET_LOADING_DISCOUNTS', true)
            const response = await DiscountService.getOne(id);
            commit('SET_DISCOUNT', response.data.item)
            commit('SET_LOADING_DISCOUNTS', false)
        } catch (error) {
            commit('SET_LOADING_DISCOUNTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchDiscounts({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_DISCOUNTS', true)
            const response = await DiscountService.get(keyword, pageNumber, order);
            commit('SET_DISCOUNTS', response.data)
            commit('SET_LOADING_DISCOUNTS', false)
        } catch (error) {
            commit('SET_LOADING_DISCOUNTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createDiscount({commit}, data){
        try{
            commit('SET_LOADING_DISCOUNTS', true)
            const response = await DiscountService.create(data);
            commit('SET_ALERT', { heading: 'success', message: response.data.message });
            state.new_discount = { name: '', description: '', value: null, typeDiscount: 'porcentagem', date_start: null, date_end: null, status: 'ativo' };
            router.push({path: `/form_discount/${response.data.item.id}`});
            commit('SET_SHOW_MODAL_NEW_DISCOUNT', false)
            commit('SET_LOADING_DISCOUNTS', false)
        } catch (error) {
            commit('SET_LOADING_DISCOUNTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeDiscount({commit}, data){
        try{
            commit('SET_LOADING_DISCOUNTS', true)
            const response = await DiscountService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_DISCOUNTS', false)
        } catch (error) {
            commit('SET_LOADING_DISCOUNTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getDiscount: state => state.discount,
    getNewDiscount: state => state.new_discount,
    getDiscounts: state => state.discounts,
    getLoadingDiscount: state => state.loadingDiscount,
    getShowModalNewDiscount: state => state.show_modal_new_discount
}

export default {
    state,
    getters,
    mutations,
    actions
};