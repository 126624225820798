import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`discount?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`discount/${id}`);
    },
    create(data) {
        return Api().post(`discount`, data);
    },
    update(data) {
        return Api().put(`discount/${data.id}`, data);
    }
}