// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import adm from './modules/adm';
import user from './modules/user';
import category from './modules/category';
import payment from './modules/payments';
import plan from './modules/plan';
import period from './modules/period';
import cupon from './modules/cupon';
import discount from './modules/discount';
import subscription from './modules/subscription';

export default new Vuex.Store({
    modules: {
        helpers,
        adm,
        user,
        category,
        payment,
        plan,
        period,
        cupon,
        discount,
        subscription,
    }
})
