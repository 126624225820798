// Formata a data com YYYY-MM-DD HH:mm
export function formatarDataComHora(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const hora = dataObj.getUTCHours().toString().padStart(2, '0');
    const minuto = dataObj.getUTCMinutes().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
}

// Formata a data com YYYY-MM-DD
export function formatarData(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano}`;
}

export function formatarDataInput(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia}`;
}
