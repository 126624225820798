import PlanService from '../../services/PlanService'
import { formatarDataInput, formatarDataComHora } from '@/helpers';

const state = () => ({
    loadingPlans: false,
    plan: null,
    plan_recurring: null,
    plans: [],
    plans_pending: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_plans_pending: false,
})
  
const mutations = {
    SET_PLAN: (state, payload) => {
        state.plan = payload
    },
    SET_PLAN_RECURRING: (state, payload) => {
        state.plan_recurring = payload
    },
    SET_PLANS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.plans = payload.items
        }else{
            state.plans = [...state.plans, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_PLAN_PENDING: (state, payload) => {
        state.plans_pending = payload
    },
    SET_LOADING_PLANS: (state, payload) => {
        state.loadingPlan = payload
    },
    SET_SHOW_MODAL_PLANS_PENDING: (state, payload) => {
        state.show_modal_plans_pending = payload
    },
}

const actions = {
    async fetchPlan({commit}, id){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.getOne(id);
            const data = response.data.item
            if(data){
                data.date_start = await formatarDataInput(data.date_start);
                data.date_end = await formatarDataInput(data.date_end);
                data.createdAt = await formatarDataComHora(data.createdAt);
                data.updatedAt = await formatarDataComHora(data.updatedAt);
            }
            commit('SET_PLAN', data)
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPlanByCategory({commit}){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.getPlanRecurring();
            commit('SET_PLAN_RECURRING', response.data.item)
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPlanPending({commit}, id){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.getPending(id);
            commit('SET_PLAN_PENDING', response.data)
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPlansByUser({commit}, { UserId, keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'DESC' }, limit = 12 }){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.getByUser(UserId, keyword, pageNumber, order, limit);
            commit('SET_PLANS', response.data)
            
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPlans({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'DESC' } }){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.get(keyword, pageNumber, order);
            commit('SET_PLANS', response.data)
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPlan({commit}, plan){
        try{
            commit('SET_LOADING_PLANS', true)
            const body = {
                reason: plan.name,
                auto_recurring: {
                    transaction_amount: 700,
                    // transaction_amount: plan.price,
                    currency_id: "BRL",
                    frequency: 12,
                    frequency_type: "months",
                    repetitions: null,
                    billing_day: null,
                    billing_day_proportional: false
                },
                external_reference: `${plan.name} [ PeriodId-${plan.id}-${plan.CategoryUserId}-${plan.PeriodId}]`,
                back_url: process.env.VUE_APP_NOTIFICATION_URL
            }
            const response = await PlanService.create(body);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_PLAN', false)
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadePlan({commit}, data){
        try{
            commit('SET_LOADING_PLANS', true)
            const response = await PlanService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PLANS', false)
        } catch (error) {
            commit('SET_LOADING_PLANS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPlan: state => state.plan,
    getPlanRecurring : state => state.plan_recurring,
    getPlans: state => state.plans,
    getPlansPending: state => state.plans_pending,
    getLoadingPlan: state => state.loadingPlan,
    getShowModalPlansPending: state => state.show_modal_plans_pending,
}

export default {
    state,
    getters,
    mutations,
    actions
};